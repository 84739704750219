import { CAT } from '@pumpkincare/shared';

import { CHECKOUT, PLAN_SELECTION, QUOTE_FLOW } from './categories';
import {
  DUPLICATE_PURCHASE_CONTINUE_WITH_DUPS,
  DUPLICATE_PURCHASE_MODAL_LOADED,
  PLAN_SELECTION_VERSION,
  STARTED_QUOTE,
  VISITED_CHECKOUT,
} from './events';

export function planSelectionVersion(
  annual_limit,
  copay,
  deductible,
  pepVersion,
  vetId
) {
  return {
    category: PLAN_SELECTION,
    event: PLAN_SELECTION_VERSION,
    pep_version: pepVersion,
    pep_vet: vetId,
    rec_copay: copay,
    rec_deductible: deductible,
    rec_annual_limit: annual_limit,
  };
}

export function quoteFlowStartedQuote() {
  return {
    category: QUOTE_FLOW,
    event: STARTED_QUOTE,
  };
}

export function quoteFlowVisitedCheckout(
  totalPrice,
  completedPets = [],
  currency = 'usd'
) {
  let numberOfDogs = 0;
  let numberOfCats = 0;

  completedPets.forEach(pet =>
    pet.petBreedSpecies == CAT ? numberOfCats++ : numberOfDogs++
  );

  return {
    category: QUOTE_FLOW,
    event: VISITED_CHECKOUT,
    currency,
    num_items: completedPets.length,
    num_dogs: numberOfDogs,
    num_cats: numberOfCats,
    value: parseFloat(totalPrice),
  };
}

export function duplicatePurchaseModalLoaded() {
  return {
    category: CHECKOUT,
    label: 'Duplicate Pet Popup',
    event: DUPLICATE_PURCHASE_MODAL_LOADED,
  };
}

export function duplicatePurchaseModalContinueWithDups() {
  return {
    category: CHECKOUT,
    label: 'Duplicate Pet Popup',
    event: DUPLICATE_PURCHASE_CONTINUE_WITH_DUPS,
  };
}

export function errorBannerShow({ title, message, code }) {
  return {
    category: 'Error_Validations',
    event: 'Error Banner Shown',
    errorTitle: title,
    errorMsg: message?.toString(),
    error: code || '',
  };
}

export function gaMarketingAttribution({ event, ...data }) {
  return {
    category: QUOTE_FLOW,
    event,
    data,
  };
}
